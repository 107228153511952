export default {
  data() {
    return {
      default_img: "https://s.moimg.net/m/img/placeholder/default_1x1@2x.png"
    };
  },
  props: ["item"],
  methods: {
    goCategoryList(item) {
      window.location.href = item.h5_link;
    }
  }
};