import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d5fb5bf4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tab"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["src"];
import env from "md-base-tools/env";
import { computed } from 'vue';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup(__props) {
    const state = reactive({
      tabs: [{
        icon: 'https://p6.moimg.net/path/dst_project/202207/0420/3420/2207043420VkDYN8RxAgwGyMWkY7vq6Ldba2JnK1.png',
        txt: '全部项目',
        path: '/project/list'
      }, {
        icon: 'https://p6.moimg.net/path/dst_project/202207/0420/3420/2207043420gjqZaKVxWYB0ypVwJBv2L1nOXJdwm5.png',
        txt: '榜单',
        path: '/rank'
      }, {
        icon: 'https://p6.moimg.net/path/dst_project/202207/0420/3420/22070434208Wk0eKMwDjoZEeWLJWzAJ5O9BaRm7Y.png',
        txt: '商城',
        url: 'https://m.modian.com/product/newGoods'
      }, {
        icon: 'https://p6.moimg.net/path/dst_project/202207/0420/3420/22070434204jrnKmB2kYNJENWKRLyXpM5GQ9q6Rd.png',
        txt: '摩点App下载',
        url: 'https://m.modian.com/download'
      }]
    });
    const router = useRouter();
    const jump = item => {
      if (item.path) {
        router.push({
          path: item.path
        });
      } else {
        location.href = item.url;
      }
    };
    const isShow = computed(() => env.isWeibo()); // 微博里不展示“下载app”

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).tabs, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [!(_unref(isShow) && index == 3) ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "item",
          onClick: _withModifiers($event => jump(item), ["stop"])
        }, [_createElementVNode("img", {
          src: item.icon
        }, null, 8, _hoisted_3), _createElementVNode("p", null, _toDisplayString(item.txt), 1)], 8, _hoisted_2)) : _createCommentVNode("", true)], 64);
      }), 128))]);
    };
  }
};