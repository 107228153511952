import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-60d308d1"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
import TopSearch from '@@/home/search.vue';
import TopSwiper from '@@/home/swiper.vue';
import TopTab from '@@/home/tab.vue';
import MainContent from '@@/home/doubleCols.vue';
// import Luckycards from '@@/home/luckycards.vue'

export default {
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(TopSearch), _createVNode(TopSwiper), _createVNode(TopTab), _createVNode(MainContent)]);
    };
  }
};