import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-31c621a5"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home_top"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = ["src", "fit"];
import { urlFormatToJump } from "@/service/router";
import { reactive } from 'vue';
import { homeRecommend } from "@api/index";
import { showFailToast } from "vant";
export default {
  setup(__props) {
    const state = reactive({
      fit: 'cover',
      list: []
    });
    homeRecommend({}).then(res => {
      if (res.status == 0 && res.data.old_banner_ads) {
        state.list.push(...res.data.old_banner_ads);
      } else {
        showFailToast(res.message);
      }
    });
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
        autoplay: 5000,
        "lazy-render": "",
        "indicator-color": "#fff"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).list, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("div", {
              onClick: $event => _unref(urlFormatToJump)(item.url)
            }, [_createElementVNode("img", {
              src: _ctx.$imgUrlFilter(item.show_url, 750, 321),
              fit: _unref(state).fit
            }, null, 8, _hoisted_3)], 8, _hoisted_2)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]);
    };
  }
};