import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-70562760"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "list"
};
const _hoisted_2 = ["onClick"];
import MDRouter from "@/service/router";
import { showFailToast } from 'vant';
import { feedList } from "@api/index";
// import { List, Progress as VProgress, CountDown} from 'vant';
import { List } from 'vant';
import { reactive } from 'vue';
import domain from '@/service/domain';
import CompCard from './card.vue';
export default {
  props: {
    ad_position: {
      type: String,
      default: 'recommend_feed_list'
    },
    params_string: {
      type: String,
      default: '{}'
    }
  },
  setup(__props) {
    const props = __props;
    const state = reactive({
      page: 1,
      refreshing: false,
      loading: false,
      finished: false,
      fit: 'cover',
      list: [],
      default_img: 'https://s.moimg.net/m/img/placeholder/default_1x1@2x.png'
    });

    // 翻页
    const onLoad = () => {
      setTimeout(() => {
        if (state.refreshing) {
          state.page = 1;
          state.list = [];
          state.refreshing = false;
        }
        getFeedList();
      }, 100);
    };
    const getFeedList = () => {
      let option = Object.assign(JSON.parse(props.params_string), {
        page_size: 20,
        page: state.page,
        ad_position: props.ad_position
      });
      feedList(option).then(res => {
        if (res.status == 0) {
          if (res.data.list && res.data.list.length > 0) {
            state.list.push(...res.data.list);
          }
          // 加载状态结束
          state.loading = false;
          state.page++;
          // 数据全部加载完成
          if (!res.data.is_next || res.data.list.length == 0) {
            state.finished = true;
          }
        } else {
          showFailToast(res.message);
        }
      });
    };
    // 去详情
    const goToInfo = item => {
      if (item.type == 'pro') {
        MDRouter.goProInfo(item.card_info.id);
      } else if (item.type == 'mall_pro') {
        MDRouter.goMallInfo(item.card_info.product_id);
      } else if (item.type == 'kuji') {
        location.href = `${domain.kuji}/pages/fastsell/detail/index?activity_id=${item.card_info.product_id}`;
      }
    };
    // getFeedList();

    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(List), {
        loading: _unref(state).loading,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => _unref(state).loading = $event),
        finished: _unref(state).finished,
        onLoad: onLoad
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).list, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            onClick: $event => goToInfo(item)
          }, [_createVNode(CompCard, {
            item: item
          }, null, 8, ["item"])], 8, _hoisted_2);
        }), 128))])]),
        _: 1
      }, 8, ["loading", "finished"]);
    };
  }
};